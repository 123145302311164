import Vue from 'vue'
import App from './App.vue'
import router from './_router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'

import 'flag-icon-css/css/flag-icon.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
import i18n from './i18n'
import VueMeta from 'vue-meta'
import 'video.js/dist/video-js.css'
import VideoBg from 'vue-videobg'




Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})

Vue.filter('time', timestamp => {
    return new Date(timestamp).toLocaleString()
})

Vue.filter('date', timestamp => {
    return new Date(timestamp).toLocaleDateString()
})

Vue.use(BootstrapVue)
Vue.component('video-bg', VideoBg)


Vue.config.productionTip = false



new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    mounted () {
         document.dispatchEvent(new Event('render-event'))
        }
}).$mount('#app')