import * as api from '../api'

// export const toProdDetail = ({ }, gpid) => {
//     api.toProdDetail(gpid)
//   }

export const getPrds = () => {
    return api.getPrds()
}

export const getPrdItem = ({ commit }, payload) => {
    commit('')
    return api.getPrdItem(payload)
}

export const add_to_cart = ({ commit }, payload) => {
    commit('')
    return api.add_to_cart(payload)
}

export const getCommisionLs = ({ commit }, payload) => {
    commit('')
    return api.getCommisionLs(payload)
}

export const orderConfirmCn = ({ commit }, payload) => {
    commit('')
    return api.orderConfirmCn(payload)
}

export const delShopCartId = ({ commit }, payload) => {
    commit('')
    return api.delShopCartId(payload)
}

export const getManual = ({ commit }, payload) => {
    commit('')
    return api.getManual(payload)
}

export const getForumLs = ({ commit }, payload) => {
    commit('')
    return api.getForumLs(payload)
}

export const getPdLsByCategry = ({ commit }, payload) => {
    commit('')
    return api.getPdLsByCategry(payload)
}

export const getArtByid = ({ commit }, payload) => {
    commit('')
    return api.getArtByid(payload)
}

export const getArtLs = ({ commit }, payload) => {
    commit('')
    return api.getArtLs(payload)
}

export const login = ({ commit }, payload) => {
    commit('')
    return api.login(payload)
}

export const orderConfirm = ({ commit }, payload) => {
    commit('')
    return api.orderConfirm(payload)
}

export const req = ({ commit }, payload) => {
    commit('')
    return api.req(payload)
}

export const forgetPswUsrName = ({ commit }, payload) => {
    commit('')
    return api.forgetPswUsrName(payload)
}

export const changepwd = ({ commit }, payload) => {
    commit('')
    return api.changepwd(payload)
}

export const postSave = ({ commit }, payload) => {
    commit('')
    return api.postSave(payload)
}
export const addAddr = ({ commit }, payload) => {
    commit('')
    return api.addAddr(payload)
}

export const forgetPswByEmail = ({ commit }, payload) => {
    commit('')
    return api.forgetPswByEmail(payload)
}

export const delAddr = ({ commit }, payload) => {
    commit('')
    return api.delAddr(payload)
}

export const changeLan = ({ commit }, payload) => {
    commit('changeLan', payload)
}
export const getVd = ({ commit }, payload) => {
    commit('')
    return api.getVd(payload)
}

export const getCommentDetailByid = ({ commit }, payload) => {
    commit('')
    return api.getCommentDetailByid(payload)
}

export const commentSave = ({ commit }, payload) => {
    commit('')
    return api.commentSave(payload)
}

export const promotionRate = ({ commit }, payload) => {
    commit('')
    return api.promotionRate(payload)
}

export const resetPsw = ({ commit }, payload) => {
    commit('')
    return api.resetPsw(payload)
}

export const couponFreeOrder = ({ commit }, payload) => {
    commit('')
    return api.couponFreeOrder(payload)
}

export const getForumType = () => {
    return api.getForumType()
}

export const getUnPayOrder = () => {
    return api.getUnPayOrder()
}

export const getPayOrder = () => {
    return api.getPayOrder()
}

export const getCnAddrLs = () => {
    return api.getCnAddrLs()
}

export const isMem = () => {
    return api.isMem()
}

export const getFormTitle = () => {
    return api.getFormTitle()
}



export const getImgTk = () => {
    return api.getImgTk()
}

export const getCommision = () => {
    return api.getCommision()
}

export const getPrdsCommu = () => {
    return api.getPrdsCommu()
}

export const getShopCarLs = ({ commit }, payload) => {
    return api.getShopCarLs(payload)
}

export const getNavCategoris = () => {
    return api.getNavCategoris()
}
export const warrantySave = ({ commit }, payload) => {
    commit('')
    return api.warrantySave(payload)
}

export const trackLogin = ({ commit }, payload) => {
    commit('')
    return api.trackLogin(payload)
}

export const getTrackList = ({ commit }, payload) => {
    commit('')
    return api.getTrackList(payload)
}

export const getTrackProductList = ({ commit }, payload) => {
    commit('')
    return api.getTrackProductList(payload)
}
