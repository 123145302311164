import Vue from 'vue';
import Router from 'vue-router';


// const Welcome = r => require.ensure([], () => r(require('../components/Welcome')), 'Welcome');
const Home = r => require.ensure([], () => r(require('../components/Home')), 'Home');
// const Home2 = r => require.ensure([], () => r(require('../components/Home2')), 'Home2');
// const Home3 = r => require.ensure([], () => r(require('../components/Home3')), 'Home3');
// const Store = r => require.ensure([], () => r(require('../components/Store')), 'Store');
const Home4 = r => require.ensure([], () => r(require('../components/Home4')), 'Home4');
const Dreamtime = r => require.ensure([], () => r(require('../components/Dreamtime')), 'Dreamtime');
const DreamtimeHome = r => require.ensure([], () => r(require('../components/DreamtimeHome')), 'DreamtimeHome');
const Product = r => require.ensure([], () => r(require('../components/Product')), 'Product');
const CategorySection = r => require.ensure([], () => r(require('../components/CategorySection')), 'CategorySection');
// const Community = r => require.ensure([], () => r(require('../components/Community')), 'Community');
// const Article = r => require.ensure([], () => r(require('../components/Article')), 'Article');
// const ArticleLs = r => require.ensure([], () => r(require('../components/ArticleLs')), 'ArticleLs');
// const Video = r => require.ensure([], () => r(require('../components/Video')), 'Video');
// const Customize = r => require.ensure([], () => r(require('../components/Customize')), 'Customize');
// const Manual = r => require.ensure([], () => r(require('../components/Manual')), 'Manual');
// const Forum = r => require.ensure([], () => r(require('../components/Forum')), 'Forum');
// const CommentDetail = r => require.ensure([], () => r(require('../components/CommentDetail')), 'CommentDetail');
const ForgetPsw = r => require.ensure([], () => r(require('../components/ForgetPsw')), 'ForgetPsw');
const ForgetPswEmail = r => require.ensure([], () => r(require('../components/ForgetPswEmail')), 'ForgetPswEmail');
const OrderLsPre = r => require.ensure([], () => r(require('../components/OrderLsPre')), 'OrderLsPre');
const Ucenter = r => require.ensure([], () => r(require('../components/Ucenter')), 'Ucenter');
// const Post = r => require.ensure([], () => r(require('../components/Post')), 'Post');
const Paypal = r => require.ensure([], () => r(require('../components/Paypal')), 'Paypal');
const PayErro = r => require.ensure([], () => r(require('../components/PayErro')), 'PayErro');
const PayCancle = r => require.ensure([], () => r(require('../components/PayCancle')), 'PayCancle');
// const Share = r => require.ensure([], () => r(require('../components/Share')), 'Share');
const ForgetPswReset = r => require.ensure([], () => r(require('../components/ForgetPswReset')), 'ForgetPswReset');
const AboutBluedio = r => require.ensure([], () => r(require('../components/AboutBluedio')), 'AboutBluedio');
const Business = r => require.ensure([], () => r(require('../components/Business')), 'Business');
const Gallery = r => require.ensure([], () => r(require('../components/Gallery')), 'Gallery');
// const Warranty = r => require.ensure([], () => r(require('../components/Warranty')), 'Warranty');
// const Faq = r => require.ensure([], () => r(require('../components/Faq')), 'Faq');
// const Shippingpolicy = r => require.ensure([], () => r(require('../components/ShippingReturens')), 'Shippingpolicy');
// const Memberships = r => require.ensure([], () => r(require('../components/Memberships')), 'Memberships');
// const GiftBox = r => require.ensure([], () => r(require('../components/GiftBox')), 'GiftBox');
// const WarrantyRegister = r => require.ensure([], () => r(require('../components/WarrantyRegister')), 'WarrantyRegister');
// const TrackLogin = r => require.ensure([], () => r(require('../components/TrackLogin')), 'TrackLogin');
// const TrackList = r => require.ensure([], () => r(require('../components/TrackList')), 'TrackList');
const Refresh = r => require.ensure([], () => r(require('../components/Refresh')), 'Refresh');
const Oem = r => require.ensure([], () => r(require('../components/Oem')), 'Oem');
Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        /*{
        path: '/bluedio/Welcome',
        name: 'enWelcome',
        component: Welcome
    },*/
    {
        path: '/bluedio/forgetpsw',
        name: 'cnForgetPsw',
        component: ForgetPsw
    },
    {
        path: '/user/forget/paasword',
        name: 'enForgetPsw',
        component: ForgetPsw
    },
    // {
    //     name: 'cnForgetPswEmail',
    //     component: ForgetPswEmail
    // },
    {
        path: '/user/forget/password/email',
        name: 'enForgetPswEmail',
        component: ForgetPswEmail
    },
    {
        path: '/bluedio/orderlspre',
        name: 'enOrderLsPre',
        component: OrderLsPre
    },
    {
        path: '/cn/orderlspre',
        name: 'cnOrderLsPre',
        component: OrderLsPre
    },
    {
        path: '/',
        name: 'enhome',
        component: Home4
    },
    {
        path: '/store',
        name: 'store',
        component: Home
    },
    {
        path: '/refresh',
        name: 'refresh',
        component: Refresh
    },
    {
        path: '/game/dreamtime',
        name: 'dreamtime',
        component: Dreamtime
    },
    {
        path: '/dreamtime',
        name: 'dreamtimehome',
        component: DreamtimeHome
    },
    {
        path: '/business/investment',
        name: 'business',
        component: Business
    },
    {
        path: '/business/oem',
        name: 'oem',
        component: Oem
    },
    {
        path: '/business/gallery',
        name: 'gallery',
        component: Gallery
    },
    //Category
    {
        path: '/:language/category/:categoryid',
        name: 'categorySection',
        component: CategorySection
    },

    //Product
    {
        path: '/cn/product/:proid',
        name: 'cnProduct',
        component: Product,
    },
    {
        path: '/en/product/:proid',
        name: 'enProduct',
        component: Product,
    },
    /*{
        path: '/:language/product/:proid',
        name: 'product',
        meta: {
            title: "Product",
            content: 'Product'
        },
        component: Product,
    },*/

/*
    {
        path: '/product/:proid',
        name: 'product',
        component: Product,
    },
    {
        path: '/cn/product/:proid',
        name: 'cnProduct',
        component: Product,
    },
    {
        path: '/en/product/:proid',
        name: 'enProduct',
        component: Product,
    },

    {
        path: '/de/product/:proid',
        name: 'deProduct',
        component: Product,
    },
    {
        path: '/fr/product/:proid',
        name: 'frProduct',
        component: Product,
    },
    {
        path: '/es/product/:proid',
        name: 'esProduct',
        component: Product,
    },
    {
        path: '/it/product/:proid',
        name: 'itProduct',
        component: Product,
    },
    {
        path: '/tr/product/:proid',
        name: 'trProduct',
        component: Product,
    },
    {
        path: '/ru/product/:proid',
        name: 'ruProduct',
        component: Product,
    },
    {
        path: '/pl/product/:proid',
        name: 'plProduct',
        component: Product,
    },
    {
        path: '/jp/product/:proid',
        name: 'jpProduct',
        component: Product,
    },
    {
        path: '/kr/product/:proid',
        name: 'krProduct',
        component: Product,
    },
    {
        path: '/vn/product/:proid',
        name: 'vnProduct',
        component: Product,
    },
    {
        path: '/th/product/:proid',
        name: 'thProduct',
        component: Product,
    },
    {
        path: '/my/product/:proid',
        name: 'myProduct',
        component: Product,
    },
    {
        path: '/id/product/:proid',
        name: 'idProduct',
        component: Product,
    },
    {
        path: '/ph/product/:proid',
        name: 'phProduct',
        component: Product,
    },
    {
        path: '/in/product/:proid',
        name: 'inProduct',
        component: Product,
    },
    {
        path: '/sa/product/:proid',
        name: 'saProduct',
        component: Product,
    },



    {
        path: '/cn/community',
        name: 'cncommunity',
        component: Community
    },
    {
        path: '/bluedio/community',
        name: 'encommunity',
        component: Community
    },
    {
        path: '/cn/community/article/:articleid',
        name: 'cnArticle',
        component: Article
    },

    //Blog article
    {
        path: '/:language/blog/article/:articleid',
        name: 'blogArticle',
        component: Article
    },

    //Blog
    {
        path: '/:language/blog',
        name: 'articleLs',
        component: ArticleLs
    },


    {
        path: '/cn/customize',
        name: 'cnCustomize',
        component: Customize
    },
    {
        path: '/support/customize',
        name: 'enCustomize',
        component: Customize
    },
    //Video
    {
        path: '/:language/video',
        name: 'video',
        component: Video
    },
    {
        path: '/cn/manual',
        name: 'cnManual',
        component: Manual
    },
    {
        path: '/:language/instruction/book',
        name: 'manual',
        component: Manual
    },
    //Forum
    {
        path: '/:language/forum',
        name: 'forum',
        component: Forum
    },*/

    

    //Forum topic
    // {
    //     path: '/:language/topic/:commentdetailid',
    //     name: 'commentDetail',
    //     component: CommentDetail
    // },

    {
        path: '/cn/ucenter',
        name: 'cnUcenter',
        component: Ucenter
    },
    {
        path: '/bluedio/ucenter',
        name: 'enUcenter',
        component: Ucenter
    },

    //Post
    /*
    {
        path: '/:language/topic/post',
        name: 'post',
        component: Post
    },*/
    {
        path: '/cn/checkout/finish',
        name: 'cnPaypal',
        component: Paypal
    },
    {
        path: '/bluedio/checkout/finish',
        name: 'enPaypal',
        component: Paypal
    },
    {
        path: '/bluedio/paypal/error',
        name: 'payErro',
        component: PayErro
    },
    {
        path: '/bluedio/paypal/cancel',
        name: 'PayCancle',
        component: PayCancle
    },
    /*
    {
        path: '/bluedio/share',
        name: 'Share',
        component: Share
    },*/
    {
        path: '/bluedio/password/reset',
        name: 'ForgetPswReset',
        component: ForgetPswReset
    },
    //aboutus
    
    {
        path: '/about/us',
        name: 'aboutBluedio',
        component: AboutBluedio
    },
    // {
    //     path: '/cn/about/us',
    //     name: 'cnAboutBluedio',
    //     component: AboutBluedio
    // },
    // {
    //     path: '/en/about/us',
    //     name: 'enAboutBluedio',
    //     component: AboutBluedio
    // },
    // {
    //     path: '/de/about/us',
    //     name: 'deAboutBluedio',
    //     component: AboutBluedio
    // },
    // {
    //     path: '/fr/about/us',
    //     name: 'frAboutBluedio',
    //     component: AboutBluedio
    // },
    // {
    //     path: '/es/about/us',
    //     name: 'esAboutBluedio',
    //     component: AboutBluedio
    // },
    // {
    //     path: '/it/about/us',
    //     name: 'itAboutBluedio',
    //     component: AboutBluedio
    // },
    // {
    //     path: '/ru/about/us',
    //     name: 'ruAboutBluedio',
    //     component: AboutBluedio
    // },
    // {
    //     path: '/tr/about/us',
    //     name: 'trAboutBluedio',
    //     component: AboutBluedio
    // },
    // {
    //     path: '/pl/about/us',
    //     name: 'plAboutBluedio',
    //     component: AboutBluedio
    // },
    // {
    //     path: '/jp/about/us',
    //     name: 'jpAboutBluedio',
    //     component: AboutBluedio
    // },
    // {
    //     path: '/kr/about/us',
    //     name: 'krAboutBluedio',
    //     component: AboutBluedio
    // },

/*
    {
        path: '/:language/warranty/guidelines',
        name: 'Warranty',
        component: Warranty
    },
    {
        path: '/:language/faq',
        name: 'faq',
        component: Faq
    },
    {
        path: '/:language/warranty/register',
        name: 'WarrantyRegister',
        component: WarrantyRegister
    },
    {
        path: '/:language/shipping/returens',
        name: 'Shippingpolicy',
        component: Shippingpolicy
    },
    {
        path: '/:language/memberships',
        name: 'Memberships',
        component: Memberships
    },
    {
        path: '/:language/giftbox',
        name: 'GiftBox',
        component: GiftBox
    },
    {
        path: '/market/commission/login',
        name: 'TrackLogin',
        component: TrackLogin
    },
    {
        path: '/market/commission/list',
        name: 'TrackList',
        component: TrackList
    },*/
    {
        path: '*',
        redirect: '/'
    }
    ]
});