// import req from './myReq';
import axios from 'axios'
import store from '../store'

// export const aios = axios.create({
//     baseURL: `http://jsonplaceholder.typicode.com/`,
//     headers: {
//         Authorization: 'Bearer {token}'
//     }
// })



// location: Com.lang,
//                     platform: 'user'

export function getPrds() {
    return axios({
        url: '/api/info/nav/list',
        method: 'get',
        params: {
            location: store.state.lan,
            platform: 'user'
        }
    })
}

export function getArtByid({
    aid,
    pg,
    pgs
}) {
    return axios({
        url: '/api/info/topic/item',
        method: 'get',
        params: {
            id: aid,
            page: pg,
            pageSize: pgs
        }
    })
}

export function getCommentDetailByid({
    commenid,
    pg,
    pgs
}) {
    return axios({
        url: '/api/info/topic/item',
        method: 'get',
        params: {
            id: commenid,
            page: pg,
            pageSize: pgs
        }
    })
}

export function getArtLs({
    qry,
    pg,
    pgs
}) {
    return axios({
        url: '/api/info/topic/list',
        method: 'get',
        params: {
            location: store.state.lan,
            platform: 'community',
            query: qry,
            forumid: 0,
            page: pg,
            pageSize: pgs
        }
    })
}

export function getManual({
    tp,
    pg,
    pgs
}) {
    return axios({
        url: '/api/info/manual/list',
        method: 'get',
        params: {
            type: tp,
            page: pg,
            pageSize: pgs
        }
    })
}

export function getForumType() {
    return axios({
        url: '/api/info/forum/list',
        method: 'get',
        params: {
            location: store.state.lan,
            platform: 'user'
        }
    })
}

export function getForumLs({
    foruid,
    pg,
    pgs
}) {
    return axios({
        url: '/api/info/topic/list',
        method: 'get',
        params: {
            location: store.state.lan,
            platform: 'user',
            forumid: foruid,
            page: pg,
            pageSize: pgs,
        }
    })
}

export function getFormTitle() {
    return axios({
        url: '/api/info/forum/list',
        method: 'get',
        params: {
            location: store.state.lan
        }
    })
}

export function getImgTk() {
    return axios({
        url: '/api/user/qiniu/uptoken',
        method: 'get',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
    })
}

export function getVd({
    vid
}) {
    return axios({
        url: '/api/info/video',
        method: 'get',
        params: {
            location: store.state.lan,
            id: vid
        }
    })
}

export function getPrdsCommu() {
    return axios({
        url: '/api/info/nav/list',
        method: 'get',
        params: {
            location: store.state.lan,
            platform: 'community'
        }
    })
}

export function getNavCategoris() {
    return axios({
        url: '/api/info/nav/product/list',
        method: 'get',
        params: {
            location: store.state.lan
        }
    })
}

export function getShopCarLs({trackid}) {
    return axios({
        url: '/api/user/shopcar/list',
        method: 'get',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params: {
            location: store.state.lan,
            trackid: trackid
        }
    })
}

export function getCnAddrLs() {
    return axios({
        url: '/api/user/cnaddress/list',
        method: 'get',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        }
    })
}

export function delShopCartId({
    bussid
}) {
    return axios({
        url: '/api/user/shopcar/item/delete',
        method: 'get',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params: {
            id: bussid
        }
    })
}

export function isMem() {
    return axios({
        url: '/api/user/commission/member',
        method: 'get',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params: {
            location: store.state.lan,
        }
    })
}

export function promotionRate({
    promotioncode
}) {
    return axios({
        url: '/api/user/discount/rate',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params: {
            location: store.state.lan,
            code: promotioncode
        }
    })
}

export function resetPsw({
    puuid,
    psw
}) {
    return axios({
        url: '/api/user/reset/password',
        method: 'get',

        params: {
            uuid: puuid,
            password: psw
        }
    })
}

export function getPdLsByCategry({
    ctgid,
    pg,
    pgs,
    load
}) {
    return axios({
        url: '/api/info/product/list',
        method: 'get',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params: {
            producttype: ctgid,
            location: store.state.lan,
            page: pg,
            pageSize: pgs,
            load:load
        }
    })
}

export function getPrdItem({
    proId,
    trackid
}) {
    return axios({
        url: '/api/info/product/item',
        method: 'get',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params: {
            id: proId,
            trackid: trackid
        }

    })
}

export function getUnPayOrder() {
    return axios({
        url: '/api/user/order/unpaid',
        method: 'get',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params: {
            location: store.state.lan,
        }

    })
}

export function getPayOrder() {
    return axios({
        url: '/api/user/order/paid',
        method: 'get',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params: {
            location: store.state.lan,
        }

    })
}

export function getCommisionLs({
    pg,
    pgs
}) {
    return axios({
        url: '/api/user/commission/list',
        method: 'get',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params: {
            page: pg,
            pageSize: pgs,
            location: store.state.lan,
        }

    })
}


export function getCommision() {
    return axios({
        url: '/api/user/commission',
        method: 'get',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params: {
            location: store.state.lan,
        }

    })
}


export function add_to_cart({
    skuid,
    count,
    customized,
    customizedesc
}) {

    let params = {
        'skuid': skuid,
        'count': count,
        'customized': customized,
        'customizedesc': customizedesc
    }

    return axios({
        url: '/api/user/shopcar/add',
        method: 'post',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params
    })
}

export function postSave({
    title,
    forumid,
    context
}) {
    let params = {
        'title': title,
        'forumid': forumid,
        "context": context,
        "location": store.state.lan
    }

    return axios({
        url: '/api/topic/item/save',
        method: 'post',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params
    })
}

export function login({
    usrname,
    psw
}) {
    let params = {
        'username': usrname,
        'password': psw
    }

    return axios({
        url: '/api/user/login',
        method: 'post',
        params
    })
}

export function couponFreeOrder({
    orderno,
    email,
    contact,
    phone,
    address
}) {
    let params = {
        'orderno': orderno,
        'email': email,
        'contact': contact,
        'phone': phone,
        'address': address
    }

    return axios({
        url: '/api/user/coupon/free/order',
        method: 'post',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params
    })
}

export function delAddr({
    id
}) {
    let params = {
        'id': id
    }
    return axios({
        url: '/api/user/address/delete',
        method: 'post',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params
    })
}

export function req({
    reqUsrName,
    reqPsw,
    reqEmail
}) {
    let params = {
        'username': reqUsrName,
        'password': reqPsw,
        'email': reqEmail
    }

    return axios({
            url: '/api/user/register',
            method: 'post',
            params
        })
        // usr.loginname=loginname
}


export function addAddr({
    address,
    contact,
    phone,
    area_street
}) {
    let params = {
        'id': '',
        'address': address,
        'contact': contact,
        'phone': phone,
        'area_street': area_street
    }

    return axios({
            url: '/api/user/cnaddress/add',
            method: 'post',
            headers: {
                'access_token': JSON.parse(localStorage.getItem('token'))
            },
            params
        })
        // usr.loginname=loginname
}

export function changepwd({
    password1,
    password2,
    usrnm
}) {
    let params = {
        'username': usrnm,
        'password1': password1,
        'password2': password2
    }
    return axios({
        url: '/api/user/changepwd',
        method: 'post',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params
    })
}

export function forgetPswUsrName({
    username
}) {

    let params = {
        'username': username
    }
    return axios({
        url: '/api/user/forget/password/step1',
        method: 'post',
        params
    })
}

export function forgetPswByEmail({
    username,
    email
}) {
    let params = {
        'location': 'en',
        'mail': email,
        'username': username
    }
    return axios({
        url: '/api/user/forget/password/vue',
        method: 'post',
        params
    })
}

export function orderConfirm({
    orders,
    coupoun_code,
    trackid
}) {
    // orders: [{"id":258,"count":4,"desc":""}]
    let params = {
        'location': store.state.lan,
        'orders': orders,
        'code': coupoun_code,
        'track':trackid
    }
    return axios({
        url: '/api/user/order/confirm',
        method: 'post',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params
    })

}

export function orderConfirmCn({
    contact,
    address,
    phone,
    orders,
    coupoun_code
}) {
    let params = {
        'location': 'cn',
        'orders': orders,
        'contact': contact,
        'address': address,
        'phone': phone,
        'code': coupoun_code
    }
    return axios({
        url: '/api/user/cnorder/confirm',
        method: 'post',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token'))
        },
        params
    })
}

export function commentSave({
    topicid,
    parentid,
    context
}) {
    let params = {
        'topicid': topicid,
        'parentid': parentid,
        'context': context
    }
    return axios({
        url: '/api/commit/item/save',
        method: 'post',
        headers: {
            'access_token': JSON.parse(localStorage.getItem('token')),
        },
        params
    })
}

export function warrantySave({
    name,
    mail,
    phone,
    buyfrom,
    productid,
    ordernum,
    serialnum
}) {
    let params = {
        'name': name,
        'mail': mail,
        'phone': phone,
        'buyfrom': buyfrom,
        'productid': productid,
        'ordernum': ordernum,
        'serialnum': serialnum
    }
    return axios({
        url: '/api/info/warranty/save',
        method: 'post',
        params
    })
}

export function trackLogin({
    username,
    password
}) {
    let params = {
        'username': username,
        'password': password
    }
    return axios({
        url: '/api/info/track/login',
        method: 'post',
        params
    })
}

export function getTrackList({
    month,
    pg,
    pgs
}) {
    return axios({
        url: '/api/user/track/list',
        method: 'get',
        headers: {
            'track_token': localStorage.getItem('site_track_token')
        },
        params: {
            month: month,
            page: pg,
            pageSize: pgs
        }

    })
}

export function getTrackProductList({
    location
}) {
    return axios({
        url: '/api/user/track/member/product/list',
        method: 'get',
        headers: {
            'track_token': localStorage.getItem('site_track_token')
        },
        params: {
            location: location
        }
    })
}


axios.interceptors.response.use(

    response => {
        const res = response.data
        if (res.result === false && res.status === "logout"){
            localStorage.removeItem("token");
            localStorage.removeItem("store");
            localStorage.removeItem("usrname");
            localStorage.removeItem("usrid");
            this.$store.state.usr = "";
            this.$store.state.usrid = "";
            this.$store.state.access_token = "";
            this.$store.state.items_cart = [];
        }
        // if (res.code !== 20000) {

        //   return Promise.reject(res.message || 'error')
        // } else {
        return res
            // }
    },
)




export function toProdDetail({
    id,
    router
}) {

    router.push({
        name: "Product",
        params: {
            proId: id
        }
    });


}