import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import createLogger from '../logger'


Vue.use(Vuex)
const state = {
  loginname: '',
  lan:localStorage.getItem('lan')==null ? (navigator.language || navigator.browserLanguage).toLowerCase().includes("zh")?"cn":"en":localStorage.getItem('lan'),
  page_cur:0,
  
  imgToke:'',
  access_token:localStorage.getItem('token')==null ?'':JSON.parse(localStorage.getItem('token')),
  serverPath:'',
  usrname:localStorage.getItem('usrname'),
  usrid:localStorage.getItem('usrid'),
  iszh:(navigator.language || navigator.browserLanguage).toLowerCase().includes("zh"),
  ismob:navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i),
  isandroid:navigator.userAgent.match(/(Android|micromessenger)/i),
  usr:{
    // "id":1009585,
    // "username":"lfpljy",
    // "password":"******",
    // "email":"lfpljy@134.com",
    // "createat":null,
    // "active":1,
    // "scoresum":0.0,
    // "amount":5.0,
    // "black":0,
    // "region":"中国|广东"
  },
  items_cart: [],
  lan_array:['en','cn','de','fr','es','it','ru','tr','pl','jp','kr','in','vn','th','my','id','ph','sa']

 
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : []

})
