export const items_cart = state => state.items_cart
export const page_cur = state => state.page_cur
export const loginname = state => {
  let lnm = state.loginname
  if (lnm == '' && state.usrname != null) {
    return state.usrname.replace(/"/g, '')
  }

  return lnm
}
export const email = state => state.usr.email
export const lan = state => state.lan
export const imgToke = state => state.imgToke
export const access_token = state => state.access_token
export const serverPath = state => state.serverPath
export const iszh = state => state.iszh
export const ismob = state => state.ismob
export const isandroid = state => state.isandroid

export const total_money = state => {
  var total = 0
  for (var i = 0; i < state.items_cart.length; i++) {
    let item = state.items_cart[i]
    total = total + ( item.sku.fans==1?item.sku.fansprice:item.sku.price) * item.count * item.currency
  }


  return (state.lan=='jp')?parseFloat(total).toFixed(0):parseFloat(total).toFixed(2)
}

export const symble_money = state => {
  if (state.lan == 'cn'){
    return '¥ ';
  }else if(state.lan == 'de' || state.lan == 'fr' || state.lan == 'es' || state.lan == 'it' || state.lan == 'pl'){
    return '€EUR ';
  }else if(state.lan == 'jp'){
    return '¥JPY ';
  }else if(state.lan == 'th'){
    return '฿THB ';
  }else if(state.lan == 'my'){
    return 'MYR ';
  }else if(state.lan == 'ph'){
    return 'PHP ';
  }
  return '$USD ';
}

// export const lang = state => {
//   var langg = "English"
//   if (state.lan == "cn"){
//     langg = "中国"
//   }else if(state.lan == "de"){
//     langg = "Deutschland"
//   }else if(state.lan == "fr"){
//     langg = "France"
//   }else if(state.lan == "es"){
//     langg = "España"
//   }else if(state.lan == "it"){
//     langg = "Italia"
//   }else if(state.lan == "ru"){
//     langg = "Россия"
//   }else if(state.lan == "tr"){
//     langg = "Türkiye"
//   }else if(state.lan == "pl"){
//     langg = "Polska"
//   }else if(state.lan == "jp"){
//     langg = "日本"
//   }else if(state.lan == "kr"){
//     langg = "대한민국"
//   }else if(state.lan == "in"){
//     langg = "India"
//   }else if(state.lan == "vn"){
//     langg = "Việt Nam"
//   }else if(state.lan == "th"){
//     langg = "ประเทศไทย"
//   }else if(state.lan == "my"){
//     langg = "Horai Nishigo"
//   }else if(state.lan == "id"){
//     langg = "Inzai"
//   }else if(state.lan == "ph"){
//     langg = "Disiplina"
//   }else if(state.lan == "sa"){
//     langg = "مملكة عربية سعودية"
//   }
//   return langg
// }

export const lang = state => {
  var langg = "English"
  if (state.lan == "cn"){
    langg = "中文"
  }else if(state.lan == "de"){
    langg = "Deutsche"
  }else if(state.lan == "fr"){
    langg = "Français"
  }else if(state.lan == "es"){
    langg = "Español"
  }else if(state.lan == "it"){
    langg = "Italiano"
  }else if(state.lan == "ru"){
    langg = "Pусский"
  }else if(state.lan == "tr"){
    langg = "Türk"
  }else if(state.lan == "pl"){
    langg = "Polskie"
  }else if(state.lan == "jp"){
    langg = "日本語"
  }else if(state.lan == "kr"){
    langg = "한국어"
  }else if(state.lan == "in"){
    langg = "हिंदी"
  }else if(state.lan == "vn"){
    langg = "Việt"
  }else if(state.lan == "th"){
    langg = "ภาษาไทย"
  }else if(state.lan == "my"){
    langg = "Malay"
  }else if(state.lan == "id"){
    langg = "Indonesia"
  }else if(state.lan == "ph"){
    langg = "Pilipino"
  }else if(state.lan == "sa"){
    langg = " عربي ،"
  }
  return langg
}