import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
// Ready translated locale messages
const messages = {
    cn: {
        message: {
            Dream_time: "梦廊",
            Game: "游戏",
            Game_title: "梦廊是解谜游戏动漫，也是通过努力实现梦想的一个小孩故事",
            Coming_soon: "即将发布...",
            dtip_co: "DTIP联名产品",
            dtip_category: "全部产品",
            dtip_co_1: "音频产品",
            dtip_co_1_1: "入耳式",
            dtip_co_1_2: "头戴式",
            dtip_co_1_3: "音箱",
            dtip_co_2: "服装",
            dtip_co_2_1: "男装",
            dtip_co_2_2: "女装",
            Business: "业务",
            Business_investment: "业务招商",
            Business_oem: "OEM & ODM & OBM",
            Image_Gallery: "图库",
            Image_Role: "角色",
            Business_info: "需要商业用途请与我们联系",
            Co_branded: "联名",
            Game_buy: "官方购买",
            Game_Steam_buy: "Steam购买",
            Categories: "商品分类",
            Store: "商城",
            Community: "社区",
            Save: "保存",
            Register: "注册",
            Sign_Up: "注册",
            Login: "登录",
            UserName: "用户名",
            News: "新闻",
            Service: "服务",
            Memberships: "会员关系",
            AllProducts:"所有产品",
            Aboutus: "关于我们",
            Support: "支持",
            Corporate: "企业",
            Customized_Service: "定制服务",
            APP_Download: "APP下载",
            Instruction_Book: "说明书下载",
            Video: "视频",
            Forum: "论坛",
            Password: "密码",
            LOST_PASSWORD: "忘记密码?",
            Username_Email_Phone: "用户名/邮箱/电话号码",
            Sign_Up_Failed: "注册失败",
            email: "邮箱",
            My_Cart: "我的购物车",
            CART_TOTAL: "合计",
            POST: "发帖",
            Company: "公司资讯",
            Service_Item: "服务项目",
            Company_Dynamic: "公司动态",
            Talent_Recruitment: "人才招募",
            Cooperation: "合作",
            After_sale: "售后",
            Product_Verification: "防伪查询:",
            Read_more: "更多",
            Commit: "提交",
            Title: "主题",
            Please_Enter_Title: "请输入标题",
            Please_Enter_Topic_Type: "请选择主题",
            Topic_Type: "主题分类",
            You_need_fill_all_input: "请填上所有选项!!",
            Please_Log_In: "请登录",
            The_Comment_Is_Blank: "评论不能为空!",
            STOCK: "库存",
            QUANTITY: "数量",
            ADD_TO_CART: "加入购物车",
            Product_Description: "产品描述",
            COLORS: "颜色",
            COLOR: "颜色",
            Load_More: "加载更多",
            Insufficient_quantity: "数量不足",
            Add_Success: "添加成功",
            Shopping_Bag: "购物车",
            Checkout_Details: "付款细节",
            Complete_Order: "完成支付",
            Description: "产品信息	",
            Shipping_Itmes: "产品信息  ",
            Customize_message: "定制信息	",
            Subtotal: "小计",
            Total: "总计",
            Submit_Order: "提交订单",
            The_Cart_Empty: "购物车为空",
            SHOPPING_CART_TOTALS: "我的购物车",
            Transaction_Record: "交易记录",
            My_Order: "订单列表",
            Change_Password: "修改密码",
            Welcome_you: "欢迎你",
            Personal_Center: "个人中心",
            Reply: "回复",
            Tran_Addr: "配送地址",
            Manual: "操作手册",
            Security: "防伪",
            Customize: "定制",
            Promotions: "相关促销",
            Bluedio_News: "蓝弦新闻",
            Product_Name: "产品名",
            Count: "数量",
            Price: "价格",
            Unpaid_Order: "未支付",
            Paid_Order: "已支付",
            Order_Number: "订单号",
            Order_Date: "日期",
            Payment_Way: "支付方式",
            Total: "金额",
            Recruit: "招募",
            Order_Status: "交易状态",
            Logout: "登出",
            Confirm_Psw: "确认密码",
            Retrieve_Password: "找回密码",
            Email_Not_Ass_Usr: "没有找到与邮箱关联的用户.",
            Next_Step: "下一步",
            Welcome_to_Bluedio_mall: "蓝弦商城欢迎您",
            You_logout: "您还没有登录",
            Customize_your_product: "定制您的专属产品",
            None: "算了",
            Sure: "确定",
            Cancle: "取消",
            Addr: "地址",
            Tel: "电话号码",
            Shipping_address: "收货地址",
            Success: "成功",
            To_Checkout: "去支付",
            Billing_Address: "寄付帐单地址",
            Total_Amount: "总价",
            Currency_Code: "货币代码",
            Your_Contact_Information: "您的联系方式",
            Telephone_Format: "电话号码",
            Please_Enter_Phone: "请输入电话号码",
            Please_Enter_UsrName: "请输入用户名",
            Please_Enter_Passward: "请输入密码",
            Please_Enter_Passward_Again: "请再次输入密码",
            Please_enter_the_coupon_code: "请输入优惠码",
            Commission_account: "佣金流水帐",
            Brokerage: "佣金",
            Related_Video_Recommendation: "相关视频推荐",
            All: "所有",
            Headband: "头戴式",
            In_ear: "入耳式",
            Sound: "音响",
            Others: "其他",
            Buyer: "购买者",
            Current_commission: "当前佣金",
            Withdraw: "提现",
            Withdraw_time: "确认收货后30天后可提现",
            Share_0: "成为蓝弦销售伙伴的规则:",
            Share_1: "2019年7月1号之前在蓝弦官网消费即可自动升级成为蓝弦伙伴;",
            Share_2: "2019年7月1号之后则需在蓝弦官网购物满￥1500才能获得销售伙伴资格;",
            Share_3: "蓝弦销售伙伴用本人帐号在官网下单一律优惠20%;",
            Share_4: "蓝弦销售伙伴在个人中心获取专属的数字码",
            Share_5: "用户使用销售伙伴的专属数字码购买产品即享5%的优惠;",
            Share_6: "提供数字码的销售伙伴将获得20%的佣金奖励;",
            Share_7: "销售伙伴可根据[佣金赚取栏]了解产品购买状态;",
            Share_8: "当[佣金赚取栏]显示可提现时,联系蓝弦官方提现;",
            Please_enter_the_email: "请输入邮箱",
            Submit: "提交",
            Psw_Same: "两次密码请一致",
            Discount_price: "优惠价",
            Recommendation_code: "您的推荐码",
            Enter_your_product_code: "输入您的产品码",
            Add_cart: "已加入购物车",
            Buy_now: "立即购买",
            Coupon_now: "会员优惠",
            Usr_Psw_Erro: "用户名或密码错误.",
            Not_member: "您还不是蓝弦销售伙伴.",
            Can_withdraw: "可提现",
            Pre_acquisition: "预提现",
            Invalid: "无效",
            Commit_failed: "提交失败",
            couponTitle: "Free exchange, contact information",
            couponEmail: "Email",
            couponPhone: "Phone",
            couponCountry: "Country",
            couponContact: "Contact",
            couponAddress: "Address",
            couponZip: "ZIP",
            Driver_Message: "7.1虚拟声卡驱动",
            Bluedio_Mall: "梦廊dream time-大型解谜人解谜端游PC游戏 | DTIP",
            Bluedio_Mall_Tagline: "梦廊dream time-大型解谜人解谜端游PC游戏 | DTIP",
            Bluedio_Mall_Keywords: "梦廊dream time-大型解谜人解谜端游PC游戏 | DTIP",
            Bluedio_Mall_Description: "梦廊dream time-大型解谜人解谜端游PC游戏 | DTIP",
            Bluedio_Worry_Title: "Worry-free Shopping",
            Bluedio_Worry_free: "Worry-free Shopping:DTIP provide 30-day money back and one year manufacturer's warranty on the products......",
            Back: "返回",
            Off: "",
            Mine: "我的",
            Home: "首页",
            Recommend: "推荐",
            Chang_Lan: "切换语言",
            Unpay_cancle: "未支付订单会在24H后自动删除",
            Old_password: "旧密码",
            Confirm_password: "确认密码",
            Remove: "移除",
            Author: "作者",
            Published_on: "发布于",
            Contact: "咨询",
            Modify_password_success: "修改密码成功",
            Modify_password_failed: "修改密码失败",
            Password_must_the_same: "密码必须相同",
            Region_limit: "当前不支持发货",
            Free_ExpressDelivery: "免运费,发货地:",
            Discount_ends_in: "优惠结束于 ",
            Discount_unit_day: "天 ",
            Following: "Following:",
            Privacy_of_Policy: "使用条款，政策隐私",
            Faq: "常见问题",
            Register_for_Warranty: "注册保修",
            Warranty_Guidelines: "保修指引",
            Shipping_Returens: "运输与退货",
            Instruction_Book: "产品使用说明书",
            Blog: "Blog",
            Gift_Box: "礼物盒",
            Previous: "上一页",
            Next: "下一页",
            Warranty_register_name: "姓名",
            Warranty_register_email: "邮箱",
            Warranty_register_phone: "电话",
            Warranty_register_buyfrom: "从哪里购买",
            Warranty_register_product: "选择产品",
            Warranty_register_Ordernum: "订单号",
            Warranty_register_Serialnum: "序列号",
            Warranty_register_Title: "注册",
            Warranty_register_subTitle: "YOUR NOISE PRODUCT",
            Warranty_register_submit: "现在注册",
            Fans_discount: "粉丝优惠",
        },
    },
    en: {
        message: {
            Dream_time: "Dream time",
            Game: "Game",
            Game_title: "Dream time is a puzzle game and anime, and it is also a story of a child who realizes his dream through hard work.",
            Business: "Business",
            Business_investment: "Business investment",
            Business_oem: "OEM & ODM & OBM",
            Coming_soon: "Coming soon...",
            dtip_category: "All Product",
            dtip_co: "DTIP Co-branded",
            dtip_co_1: "Audio products",
            dtip_co_1_1: "Earbuds",
            dtip_co_1_2: "Headphone",
            dtip_co_1_3: "Speaker",
            dtip_co_2: "Clothing",
            dtip_co_2_1: "Men's clothing",
            dtip_co_2_2: "Women's clothing",
            Image_Gallery: "Gallery",
            Image_Role: "Role",
            Business_info: "For commercial use, please contact us",
            Co_branded: "Co-branded",
            Game_buy: "Official purchase",
            Game_Steam_buy: "Steam purchase",
            Categories: "Categories",
            Store: "Store",
            Community: "Community",
            Save: "Save",
            Register: "Register",
            Sign_Up: "Sign Up",
            Login: "Login",
            UserName: "UserName",
            News: "News",
            Service: "Service",
            Memberships: "Memberships",
            AllProducts:"All Products",
            Aboutus: "About US",
            Support: "Support",
            Corporate: "Corporate Enquiry",
            Customized_Service: "Customized Service",
            APP_Download: "APP Download",
            Instruction_Book: "Instruction Book",
            Video: "Video",
            Forum: "Forum",
            Password: "Password",
            LOST_PASSWORD: "LOST PASSWORD?",
            Username_Email_Phone: "UserName/Email/Phone",
            Sign_Up_Failed: "Sign up failed",
            email: "Email",
            My_Cart: "My Cart",
            CART_TOTAL: "Cart Total",
            POST: "POST",
            Company: "Company",
            Service_Item: "Service",
            Company_Dynamic: "Company Dynamic",
            Talent_Recruitment: "Talent Recruitment",
            Cooperation: "Cooperation",
            After_sale: "After sale",
            Product_Verification: "Product Verification:",
            Read_more: "Read more",
            Commit: "Commit",
            Title: "Title",
            Please_Enter_Title: "Please Enter Title",
            Please_Enter_Topic_Type: "Please Enter Topic Type",
            Topic_Type: "Topic Type",
            You_need_fill_all_input: "You need fill all input!!",
            Please_Log_In: "Please Login",
            The_Comment_Is_Blank: "The comment is blank!",
            STOCK: "Stock",
            QUANTITY: "Quantity",
            ADD_TO_CART: "Add to Cart",
            Product_Description: "Product Description",
            COLORS: "Colors",
            COLOR: "Color",
            Load_More: "Load More",
            Insufficient_quantity: "Insufficient quantity",
            Add_Success: "Add Success",
            Shopping_Bag: "Cart",
            Checkout_Details: "Checkout Details",
            Complete_Order: "Complete Order",
            Description: "Description",
            Shipping_Itmes: "Item(s)",
            Customize_message: "Customize",
            Subtotal: "Subtotal",
            Total: "Total",
            Submit_Order: "Submit Order",
            The_Cart_Empty: "The cart is empty",
            SHOPPING_CART_TOTALS: "SHOPPING CART TOTALS",
            Transaction_Record: "Transaction Record",
            My_Order: "My Order",
            Change_Password: "Change Password",
            Welcome_you: "Welcome",
            Personal_Center: "Personal Center",
            Reply: "Reply",
            Tran_Addr: "Delivery address",
            Manual: "Manual",
            Security: "Security",
            Customize: "Customize",
            Promotions: "Promotions",
            Bluedio_News: "Bluedio News",
            Product_Name: "Product Name",
            Count: "Count",
            Price: "Price",
            Unpaid_Order: "Unpaid orders",
            Paid_Order: "Paid Order",
            Order_Number: "Order Number",
            Order_Date: "Order Date",
            Payment_Way: "Payment Way",
            Total: "Total",
            Recruit: "Recruit",
            Order_Status: "Order Status",
            Logout: "Logout",
            Confirm_Psw: "Confirm password",
            Retrieve_Password: "Retrieve Password",
            Email_Not_Ass_Usr: "Did not find mail associated users.",
            Next_Step: "Next Step",
            Welcome_to_Bluedio_mall: "Welcome to Bluedio mall",
            You_logout: "You are not logged in yet",
            Customize_your_product: "Customize your product",
            None: "None",
            Sure: "Sure",
            Cancle: "Cancle",
            Addr: "Address",
            Tel: "Tel.",
            Shipping_address: "Shipping Address",
            Success: "Success",
            To_Checkout: "To Checkout",
            Billing_Address: "Billing Address",
            Total_Amount: "Total Amount",
            Currency_Code: "Currency Code",
            Your_Contact_Information: "Your Contact Information",
            Telephone_Format: "Telephone Format",
            Please_Enter_Phone: "Please Enter Phone",
            Please_Enter_UsrName: "Please Enter Name",
            Please_Enter_Passward: "Please Enter Password",
            Please_Enter_Passward_Again: "Please enter the password again",
            Please_enter_the_coupon_code: "Please enter the coupon code",
            Commission_account: "Commission Flow",
            Brokerage: "Brokerage",
            Related_Video_Recommendation: "Related Video Recommendation",
            All: "All",
            Headband: "Headband",
            In_ear: "In-ear",
            Sound: "Sound",
            Others: "Others",
            Buyer: "Buyer",
            Current_commission: "Current commission",
            Withdraw: "Withdraw",
            Withdraw_time: "Confirmation can be withdrawn 30 days after receipt",
            Share_0: "Rules to become a member of Bluedio:",
            Share_1: "Customers,who purchase our products on Bluedio offcial store before July 1,2019 will become Bluedio members;",
            Share_2: "After July 1,2019,still,you will gain a opportunity to be a member when your orders amount exceed to $200;",
            Share_3: "Bluedio members will be eligible for a 20% discount when they purchase products with their own account on the offical store;",
            Share_4: "Members acquires a personal code on the Personal Central;",
            Share_5: "User will get a 5% discount when they purchase Bluedio's products with member's personal code;",
            Share_6: "Member who share their code to the user will receive a 20% commission;",
            Share_7: "Member can realize about Order Status according to Commission Flow;",
            Share_8: "Please contact our customer-services staff to help to withdrawal when the Commission Flow show that you're allow to withdrawal;",
            Please_enter_the_email: "Please enter the email",
            Submit: "Submit",
            Psw_Same: "Please enter the same password twice",
            Discount_price: "Discount price",
            Recommendation_code: "Your recommendation code",
            Enter_your_product_code: "Enter your product code",
            Add_cart: "Successful addition of shopping carts",
            Buy_now: "Buy Now",
            Coupon_now: "Member benefits",
            Usr_Psw_Erro: "The email and password you entered did not match. Please try again.",
            Not_member: "You are not the Bluedio member yet.",
            Can_withdraw: "Can withdraw",
            Pre_acquisition: "Pre-acquisition",
            Invalid: "Invalid",
            Commit_failed: "Commit failed",
            couponTitle: "Free exchange, contact information",
            couponEmail: "Email",
            couponPhone: "Phone",
            couponCountry: "Country",
            couponContact: "Contact",
            couponAddress: "Address",
            couponZip: "ZIP",
            Driver_Message: "7.1 Virtual sound card driver",
            Bluedio_Mall: "dream time-Super puzzle-solving game computer game PC game STEAM puzzle game",
            Bluedio_Mall_Tagline: "dream time-Super puzzle-solving game computer game PC game STEAM puzzle game.",
            Bluedio_Mall_Keywords: "dream time-Super puzzle-solving game computer game PC game STEAM puzzle game.",
            Bluedio_Mall_Description: "dream time-Super puzzle-solving game computer game PC game STEAM puzzle game.",
            Bluedio_Worry_Title: "Worry-free Shopping",
            Bluedio_Worry_free: "Worry-free Shopping:Provide 30-day money back and one year manufacturer's warranty on the products......",
            Back: "back",
            Off: "Off",
            Mine: "Mine",
            Home: "Home",
            Recommend: "Recommend",
            Chang_Lan: "Switch Language",
            Unpay_cancle: "Unpaid orders are automatically deleted after 24H",
            Old_password: "Old password",
            Confirm_password: "Confirm password",
            Remove: "Remove",
            Author: "Author",
            Published_on: "Published on",
            Contact: "Contact",
            Modify_password_success: "Modify password success",
            Modify_password_failed: "Modify password failed",
            Password_must_the_same: "password must the same",
            Region_limit: "Sorry, shipping is not supported in your area.",
            Free_ExpressDelivery: "Fast,Free Shipping,Shipping from",
            Discount_ends_in: "Discount ends in ",
            Discount_unit_day: "day ",
            Following: "Following:",
            Privacy_of_Policy: "Terms of Use , Privacy of Policy",
            Faq: "FAQ",
            Register_for_Warranty: "Register Warranty",
            Warranty_Guidelines: "Warranty Guidelines",
            Shipping_Returens: "Shipping & Returens",
            Instruction_Book: "Instruction Book",
            Blog: "Blog",
            Gift_Box: "Gift Box",
            Previous: "Previous",
            Next: "Next",
            Warranty_register_name: "Name",
            Warranty_register_email: "Email",
            Warranty_register_phone: "Phone",
            Warranty_register_buyfrom: "Where did you buy from",
            Warranty_register_product: "Please select your product",
            Warranty_register_Ordernum: "Order Number",
            Warranty_register_Serialnum: "Serial Number",
            Warranty_register_Title: "Register",
            Warranty_register_subTitle: "YOUR NOISE PRODUCT",
            Warranty_register_submit: "Register Now",
            Fans_discount: "Fan Offer",
        },
    },
};
const numberFormats = {
    en: {
        currency: {
            style: "currency",
            currency: "USD",
        },
    },
    cn: {
        currency: {
            style: "currency",
            currency: "RMB",
            currencyDisplay: "symbol",
        },
    },

    
};
export default new VueI18n({
    // locale: localStorage.getItem("lan") == null ? "en" : localStorage.getItem("lan"), 
    locale: (navigator.language || navigator.browserLanguage).toLowerCase().includes("zh")?"cn":"en",
    messages, 
    numberFormats,
});