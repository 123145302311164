<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("message.Bluedio_Mall"),
      // all titles will be injected into this template
      titleTemplate: "%s",
      meta: [
        {
             name: "keywords",
        content: this.$t("message.Bluedio_Mall_Keywords")
        },
        {
             name: "tagline",
        content: this.$t("message.Bluedio_Mall_Tagline")
        },
        {
             name: "description",
        content: this.$t("message.Bluedio_Mall_Description")
        }
      ]
    };
  }
};
</script>
